
<template>
    <CRow class="mt-3">
        <CCol col="12" xl="12">
            <CCol sm="12">
                <CRow>
                    <CCol sm="4" >
                        <CSelect
                            :label="$t('label.group')"
                            :horizontal="{label:'col-sm-11 col-lg-3 col-xl-2', input:'col-sm-11 col-lg-9 col-xl-9'}"
                            :options="optionList"
                            :disabled="loading"
                            :value.sync="$store.state.contenedores.filtroCargo"
                            @input="$store.state.contenedores.filtroCargo = $event.target.value"
                        />
                    </CCol>
                    <CCol sm="8" class="d-flex align-items-center justify-content-end">
                        <CButton
                            color="add"
                            @click="activarModal()"
                            v-c-tooltip="{
                                content: $t('label.view'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nueva')}}
                        </CButton>
                        <CButton
                            color="excel"
                            style="margin-left:5px;"
                            @click="generarXLS(true)"
                            v-c-tooltip="{
                                content: $t('label.exportToExcel'),
                                placement: 'top-end'
                            }"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XLSX
                        </CButton>
                    </CCol>
                </CRow>
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    :loading="loading"
                     
                >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #TpCargoViewId="{ index }">
                        <td class="center-cell">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="center-cell">
                            <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="center-cell">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                v-c-tooltip="{
                                    content: $t('label.edit')+' '+$t('label.view'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import Reportes from '@/_mixins/reportes';

    function fields(){
        return [
            { key: 'TpCargoViewId',label: '#',filter:false, _style: 'width:4%; text-align:center', },
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'width:18%;', },
            { key: 'TpCargoViewName',label: this.$t('label.view'), _style:'width:18%;', },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:14%;',  },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:14%;', _classes: 'text-center' },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:12%; text-align:center;', },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 50px; width:5%', 
                sorter: false, 
                filter: false
            },
        ];
    }

    //data
    function data() {
        return {
            activePage: 1,
        }
    }

    //methods
    async function generarXLS(valor){ 
        if(this.computedItems.length !== 0){
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.computedItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.group')] = item.TpCargoName;
                objeto[this.$t('label.view')] = item.TpCargoViewName;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                itemsArray.push(objeto);
            });
            let nombreLibro = this.$i18n.locale == 'es' ? 'VistaDeContenedores' : 'ContainerViews';
            
            await this.getExcelArray(itemsArray, this.$t('label.containerLists.viewsLists'), valor, '', false, nombreLibro);
            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
        }
    }
    function activarModal(){
        this.$store.state.contenedores.modalVista = true;
    }

    function updateModal(item){
        this.$store.state.contenedores.modalVista = true;
        this.$store.commit('contenedores/asignarid', item.TpCargoViewId);
    }
    

    //computed
    function computedItems () {
        return this.$store.getters["contenedores/myDataViewtable"];
    }

    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    function optionList(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 3){
            const idView = this.filtroCargo;
            this.$store.dispatch('contenedores/getViewlist',{ idView });
        }
    }
    function filtroCargo(newValue,OldValue){
        if(this.tabIndex === 3){
            const idView = newValue;
            this.$store.dispatch('contenedores/getViewlist', { idView } );
        }
    }
    function modalVista(newValue){
        if(newValue === false){
            const idView = this.filtroCargo;
            this.$store.dispatch('contenedores/getViewlist', { idView } );
        }
    }
    export default {
        name: 'index-views',
        data,
        props: ['loading'],    
        mixins: [
            GeneralMixin,
            Reportes
        ],
        methods: {
            activarModal,
            updateModal,
            generarXLS,
        },
        computed: {
            computedItems,
            formatedItems,
            optionList,
            fields,
            ...mapState({
                tabIndex: state => state.contenedores.tabIndex,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                filtroCargo: state => state.contenedores.filtroCargo,
                modalVista: state=> state.contenedores.modalVista,
            })
        },
        watch:{
            tabIndex,
            filtroCargo,
            modalVista
        }
    }   
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>